export default [
	{
		header: 'Telemarketing',
	},
	{
		title: 'Calon Agen',
		icon: 'UserIcon',
		route: 'calon-agen',
	},
	{
		title: 'News Notification',
		icon: 'UserIcon',
		route: 'notifikasi-news',
	},
]
