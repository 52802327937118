export default [
	{
		header: 'Human Resource',
	},
	{
		title: 'Karir',
		icon: 'UserIcon',
		route: 'karir',
	},
]
